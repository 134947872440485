<template>
  <a-modal
    :footer="null"
    :maskClosable="false"
    title="操作日志"
    style="top: 8px;"
    width="80%"
    v-model="visible" >
    <div class="query">
      <a-form-model :labelCol="{span: 7}" :wrapperCol="{span:17}">
        <a-row :gutter="4">
          <a-col :md="10">
            <a-form-model-item label="操作时间">
              <DateRange
                :startTime.sync="queryParam.startTimeString"
                :endTime.sync="queryParam.endTimeString"
              ></DateRange>
            </a-form-model-item>
          </a-col>

          <a-col :md="8">
            <a-form-model-item :labelCol="{span: 0}" :wrapperCol="{span:22,offset:1}">
              <a-button @click="toSearch(0)" type="primary" icon="search">查询</a-button>
              <a-button @click="toSearch(1)" type="default" icon="sync">重置</a-button>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </div>
    <div class="table">
      <a-table
        width="70%"
        :data-source="tableData"
        bordered
        rowKey="id"
        :pagination="page"
        @change="p=>{page=p;toSearch(2)}"
        >
        <a-table-column title="操作人" data-index="createUser" :ellipsis="true" align="center">
        </a-table-column>
        <a-table-column title="操作日志" data-index="logRemark" :ellipsis="true" align="center">
        </a-table-column>
        <a-table-column title="操作时间" data-index="createTime" :ellipsis="true" align="center">
        </a-table-column>
      </a-table>
    </div>
    <div class="footer-bts">
      <a-button type="default" @click="handleCancel">禁用</a-button>
    </div>

  </a-modal>
</template>
<script>
  const qs = require('qs')
  export default {
    data() {
      return {
        queryParam: {},
        resetQueryParam: null,
        tableData: [],
        loading: false,
        page: {
          current: 1,
          pageSize: 10,
          total: 0,
        },
        options: [],
        visible: false
      }
    },
    components: {},
    methods: {
      show(id) {
        const _this = this
        this.queryParam.id = id;
        const postData = Object.assign({}, this.queryParam, this.page,)
        this.axios.get(`/api/product/product/productTrackLog/list?${qs.stringify(postData)}`).then(res => {
          this.tableData =res.body.records
          this.page.total = res.body.total
        })
        _this.visible = true;

      },
      toSearch(type) {
        if (type == 1) {
          this.queryParam.startTimeString = null
          this.queryParam.endTimeString = null
          this.resetQueryParam ? this.queryParam = Object.assign({}, this.resetQueryParam) : this.resetQueryParam =
            Object.assign({}, this.queryParam)
        }
        this.page.pageNumber = type == 2 ? this.page.current : this.page.current = 1
        const postData = Object.assign({}, this.queryParam, this.page)
        this.axios.get(`/api/product/product/productTrackLog/list?${qs.stringify(postData)}`).then(res => {
          this.tableData =res.body.records
          this.page.total = res.body.total
        }).catch(err => {
          console.log(err._getMessages())
        })
      },
      handleCancel() {
        this.visible = false
      },
    },
    created() {

    },

  }
</script>


<style lang="less" scoped>
.footer-bts {
  text-align: right;
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid #E9E9E9;
}

</style>
